import React from "react"
import { graphql } from "gatsby"
import Helmet from "react-helmet"
import Layout from "../organisms/layout.organism"
import Post from "../organisms/post.organism"

export default function PostTemplate({ data: { mdx } }) {
  return (
    <Layout>
      <Helmet>
        <title>{mdx.frontmatter.headline} ∙ Bloomista</title>
        <meta name="description" content={mdx.frontmatter.description} />
        <link rel="canonical" href={"https://bloomista.garden/blog" + mdx.fields.slug} />
      </Helmet>
      <Post mdx={mdx}/>
    </Layout>
  )
}

export const pageQuery = graphql`
  query BlogPostQuery($id: String) {
    mdx(id: { eq: $id }) {
      id
      body
      frontmatter {
        headline
        tagline
        featuredImage
        date
        description
      }
      headings {
        value
        depth
      }
      fields {
        slug
        featuredImageRelative {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
          }
        }
      }
    }
  }
`